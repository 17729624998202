import { alpha, createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { GlobalStyles as MuiGlobalStyles } from "@mui/material";

export const brand = {
  50: "#FEF2F2",
  100: "#FDE6E5",
  200: "#FBCDCB",
  300: "#F8B4B1",
  400: "#F69B97",
  500: "#EE4036", // Main brand color
  600: "#D53A31",
  700: "#B9332B",
  800: "#9C2C25",
  900: "#80241F",
};

export const secondary = {
  50: "#F8FAFC",
  100: "#F1F5F9",
  200: "#E2E8F0",
  300: "#CBD5E1",
  400: "#94A3B8",
  500: "#334155", // Main secondary color (slate)
  600: "#1E293B",
  700: "#0F172A",
  800: "#0A1120",
  900: "#050A14",
};

export const gray = {
  50: "#F9FAFB",
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

export const green = {
  50: "#F6FEF6",
  100: "#E3FBE3",
  200: "#C7F7C7",
  300: "#A1E8A1",
  400: "#51BC51",
  500: "#1F7A1F",
  600: "#136C13",
  700: "#0A470A",
  800: "#042F04",
  900: "#021D02",
};

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      light: brand[300],
      main: brand[500],
      dark: brand[700],
      contrastText: "#ffffff",
    },
    secondary: {
      light: secondary[300],
      main: secondary[500],
      dark: secondary[700],
      contrastText: "#ffffff",
    },
    warning: {
      main: "#F7B538",
      dark: "#F79F00",
      ...(mode === "dark" && { main: "#F7B538", dark: "#F79F00" }),
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
      ...(mode === "dark" && {
        light: "#D32F2F",
        main: "#D32F2F",
        dark: "#B22A2A",
      }),
    },
    success: {
      light: green[300],
      main: green[400],
      dark: green[800],
      ...(mode === "dark" && {
        light: green[400],
        main: green[500],
        dark: green[700],
      }),
    },
    grey: {
      50: gray[50],
      100: gray[100],
      200: gray[200],
      300: gray[300],
      400: gray[400],
      500: gray[500],
      600: gray[600],
      700: gray[700],
      800: gray[800],
      900: gray[900],
    },
    divider: mode === "dark" ? alpha(gray[600], 0.3) : alpha(gray[300], 0.5),
    background: {
      default: "#fff",
      paper: gray[50],
      ...(mode === "dark" && { default: gray[900], paper: gray[800] }),
    },
    text: {
      primary: mode === "light" ? gray[900] : gray[50],
      secondary: mode === "light" ? gray[700] : gray[300],
    },
    action: {
      selected: `${alpha(brand[200], 0.2)}`,
      ...(mode === "dark" && {
        selected: alpha(brand[800], 0.2),
      }),
    },
  },
  typography: {
    fontFamily: ['"Inter"', "Roboto", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "3.5rem",
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: "-0.01em",
    },
    h2: {
      fontSize: "2.75rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.43,
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
  shadows: [
    "none",
    "0px 1px 3px rgba(0, 0, 0, 0.2)",
    "0px 1px 5px rgba(0, 0, 0, 0.2)",
    "0px 1px 8px rgba(0, 0, 0, 0.2)",
    "0px 2px 4px rgba(0, 0, 0, 0.2)",
    "0px 2px 6px rgba(0, 0, 0, 0.2)",
    "0px 3px 5px rgba(0, 0, 0, 0.2)",
    "0px 3px 6px rgba(0, 0, 0, 0.2)",
    "0px 3px 7px rgba(0, 0, 0, 0.2)",
    "0px 4px 6px rgba(0, 0, 0, 0.2)",
    "0px 4px 7px rgba(0, 0, 0, 0.2)",
    "0px 4px 8px rgba(0, 0, 0, 0.2)",
    "0px 5px 6px rgba(0, 0, 0, 0.2)",
    "0px 5px 7px rgba(0, 0, 0, 0.2)",
    "0px 5px 8px rgba(0, 0, 0, 0.2)",
    "0px 6px 6px rgba(0, 0, 0, 0.2)",
    "0px 6px 7px rgba(0, 0, 0, 0.2)",
    "0px 6px 8px rgba(0, 0, 0, 0.2)",
    "0px 7px 6px rgba(0, 0, 0, 0.2)",
    "0px 7px 7px rgba(0, 0, 0, 0.2)",
    "0px 7px 8px rgba(0, 0, 0, 0.2)",
    "0px 8px 6px rgba(0, 0, 0, 0.2)",
    "0px 8px 7px rgba(0, 0, 0, 0.2)",
    "0px 8px 8px rgba(0, 0, 0, 0.2)",
    "0px 9px 6px rgba(0, 0, 0, 0.2)",
  ],
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  spacing: 8,
  components: {
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 8,
          overflow: "clip",
          backgroundColor: "#fff",
          border: "1px solid",
          borderColor: gray[100],
          ":before": {
            backgroundColor: "transparent",
          },
          "&:first-of-type": {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          "&:last-of-type": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
          ...(theme.palette.mode === "dark" && {
            backgroundColor: gray[900],
            borderColor: gray[800],
          }),
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: "none",
          borderRadius: 8,
          "&:hover": { backgroundColor: gray[100] },
          ...(theme.palette.mode === "dark" && {
            "&:hover": { backgroundColor: gray[800] },
          }),
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: { mb: 20, border: "none" },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "10px",
          boxShadow: `0 4px 16px ${alpha(gray[400], 0.2)}`,
          "& .Mui-selected": {
            color: brand[500],
          },
          ...(theme.palette.mode === "dark" && {
            "& .Mui-selected": {
              color: "#fff",
            },
            boxShadow: `0 4px 16px ${alpha(brand[700], 0.5)}`,
          }),
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: "12px 16px",
          textTransform: "none",
          borderRadius: "10px",
          fontWeight: 500,
          ...(theme.palette.mode === "dark" && {
            color: gray[400],
            boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
            "&.Mui-selected": { color: brand[300] },
          }),
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          transition: "all 100ms ease-in",
          "&:focus-visible": {
            outline: `3px solid ${alpha(brand[500], 0.5)}`,
            outlineOffset: "2px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor: gray[50],
          borderRadius: 10,
          border: `1px solid ${alpha(gray[200], 0.8)}`,
          boxShadow: "none",
          transition: "background-color 150ms ease, border 150ms ease",
          "&:hover": {
            borderColor: brand[300],
            boxShadow: `0 0 24px ${alpha(brand[100], 0.3)}`,
          },
          ...(ownerState.variant === "outlined" && {
            background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
          }),
          ...(theme.palette.mode === "dark" && {
            backgroundColor: alpha(gray[800], 0.6),
            border: `1px solid ${alpha(gray[700], 0.3)}`,
            "&:hover": {
              borderColor: brand[700],
              boxShadow: `0 0 24px ${alpha(brand[800], 0.3)}`,
            },
            ...(ownerState.variant === "outlined" && {
              background: `linear-gradient(to bottom, ${gray[900]}, ${alpha(
                gray[800],
                0.5
              )})`,
            }),
          }),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          alignSelf: "center",
          py: 1.5,
          px: 0.5,
          background: `linear-gradient(to bottom right, ${brand[50]}, ${brand[100]})`,
          border: "1px solid",
          borderColor: `${alpha(brand[500], 0.3)}`,
          fontWeight: "600",
          "&:hover": {
            backgroundColor: brand[500],
          },
          "&:focus-visible": {
            borderColor: brand[800],
            backgroundColor: brand[200],
          },
          "& .MuiChip-label": {
            color: brand[500],
          },
          "& .MuiChip-icon": {
            color: brand[500],
          },
          ...(theme.palette.mode === "dark" && {
            background: `linear-gradient(to bottom right, ${brand[700]}, ${brand[900]})`,
            borderColor: `${alpha(brand[500], 0.5)}`,
            "&:hover": {
              backgroundColor: brand[600],
            },
            "&:focus-visible": {
              borderColor: brand[200],
              backgroundColor: brand[600],
            },
            "& .MuiChip-label": {
              color: brand[200],
            },
            "& .MuiChip-icon": {
              color: brand[200],
            },
          }),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: `${alpha(gray[200], 0.8)}`,
          ...(theme.palette.mode === "dark" && {
            borderColor: `${alpha(gray[700], 0.4)}`,
          }),
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: brand[600],
          fontWeight: 500,
          position: "relative",
          textDecoration: "none",
          "&::before": {
            content: '""',
            position: "absolute",
            width: 0,
            height: "1px",
            bottom: 0,
            left: 0,
            backgroundColor: brand[200],
            opacity: 0.7,
            transition: "width 0.3s ease, opacity 0.3s ease",
          },
          "&:hover::before": {
            width: "100%",
            opacity: 1,
          },
          ...(theme.palette.mode === "dark" && {
            color: brand[200],
          }),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "99px",
          color: gray[500],
          fontWeight: 500,
          ...(theme.palette.mode === "dark" && {
            color: gray[300],
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundImage: "none",
          backgroundColor: gray[100],
          padding: theme.spacing(4),
          borderRadius: theme.spacing(2),
          boxShadow: `0 3px 10px ${alpha(gray[900], 0.2)}`,
          ...(theme.palette.mode === "dark" && {
            backgroundColor: alpha(gray[900], 0.6),
          }),
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxSizing: "border-box",
          width: 36,
          height: 24,
          padding: 0,
          transition: "background-color 100ms ease-in",
          "&:hover": {
            "& .MuiSwitch-track": {
              backgroundColor: brand[600],
            },
          },
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(13px)",
            },
          },
          "& .MuiSwitch-track": {
            borderRadius: 50,
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#FFF",
            width: 16,
            height: 16,
            margin: 2,
          },
          ...(theme.palette.mode === "dark" && {
            width: 36,
            height: 24,
            padding: 0,
            transition: "background-color 100ms ease-in",
            "&:hover": {
              "& .MuiSwitch-track": {
                backgroundColor: brand[600],
              },
            },
            "& .MuiSwitch-switchBase": {
              "&.Mui-checked": {
                transform: "translateX(13px)",
              },
            },
            "& .MuiSwitch-thumb": {
              boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.2)",
              backgroundColor: "#FFF",
              width: 16,
              height: 16,
              margin: 2,
            },
          }),
        }),
        switchBase: {
          height: 24,
          width: 24,
          padding: 0,
          color: "#fff",
          "&.Mui-checked + .MuiSwitch-track": {
            opacity: 1,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& label .Mui-focused": {
            color: "white",
          },
          "& .MuiInputBase-input": {
            boxSizing: "border-box",
            "&::placeholder": {
              opacity: 0.7,
            },
          },
          "& .MuiOutlinedInput-root": {
            boxSizing: "border-box",
            minWidth: 280,
            minHeight: 40,
            height: "100%",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: gray[200],
            transition: "border-color 120ms ease-in",
            "& fieldset": {
              border: "none",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              background: `${alpha("#FFF", 0.3)}`,
            },
            "&:hover": {
              borderColor: brand[300],
            },
            "&.Mui-focused": {
              borderColor: theme.palette.primary.main,
              boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`,
              outline: "none",
              outlineColor: brand[200],
            },
          },
          ...(theme.palette.mode === "dark" && {
            "& .MuiOutlinedInput-root": {
              boxSizing: "border-box",
              minWidth: 280,
              minHeight: 40,
              height: "100%",
              borderRadius: "10px",
              border: "1px solid",
              borderColor: gray[600],
              transition: "border-color 120ms ease-in",
              "& fieldset": {
                border: "none",
                boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.4)",
                background: `${alpha(gray[800], 0.4)}`,
              },
              "&:hover": {
                borderColor: brand[300],
              },
              "&.Mui-focused": {
                borderColor: brand[400],
                outline: "none",
                outlineColor: alpha(brand[500], 0.5),
              },
            },
          }),
        }),
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          transition: "transform 150ms ease-in-out",
          "&:hover": {
            transform: "scale(1.02)",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: brand[500],
          boxShadow: `0 3px 5px ${alpha(brand[500], 0.2)}`,
          ...(theme.palette.mode === "dark" && {
            backgroundColor: brand[700],
          }),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: brand[500],
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "none",
          fontWeight: 500,
          "&.Mui-selected": {
            color: brand[500],
          },
          ...(theme.palette.mode === "dark" && {
            "&.Mui-selected": {
              color: brand[300],
            },
          }),
        }),
      },
    },
  },
});

export default function getLPTheme(mode) {
  return createTheme(getDesignTokens(mode));
}

export const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={(theme) => ({
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
        margin: 0,
        padding: 0,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      a: {
        textDecoration: "none",
        color: "inherit",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    })}
  />
);
