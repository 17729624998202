import React from "react";
import { House, Info, Bag, Wrench, Phone } from "phosphor-react";

const navItems = [
  {
    name: "Home",
    path: "/",
    icon: <House size={24} />,
    ariaLabel: "Go to Home page",
  },
  {
    name: "About",
    path: "/about",
    icon: <Info size={24} />,
    ariaLabel: "Go to About page",
  },
  {
    name: "Specialties",
    path: "/specialties",
    icon: <Bag size={24} />,
    ariaLabel: "Go to Specialties page",
  },
  {
    name: "Services",
    path: "/services",
    icon: <Wrench size={24} />,
    ariaLabel: "Go to Services page",
  },
  {
    name: "Contact",
    path: "/contact",
    icon: <Phone size={24} />,
    ariaLabel: "Go to Contact page",
  },
];

export default navItems;
