import React, { createContext, useState, useMemo, useCallback } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import getLPTheme from "./theme";

const ThemeContext = createContext({
  toggleTheme: () => {},
  theme: "light",
});

const ThemeProvider = ({ children }) => {
  // Default to light mode without checking local storage or system preference
  const [theme, setTheme] = useState("light");

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  }, []);

  const themeMode = useMemo(
    () => ({
      toggleTheme,
      theme,
    }),
    [theme, toggleTheme]
  );

  const currentTheme = getLPTheme(theme);

  return (
    <ThemeContext.Provider value={themeMode}>
      <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
