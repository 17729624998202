import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  Box,
  Container,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          py: isHomePage ? 0 : { xs: 4, sm: 6, md: 12 },
          mt: isHomePage ? 0 : { xs: 2, sm: 3, md: 4 },
          mb: isHomePage ? 0 : { xs: 2, sm: 3, md: 4 },
        }}
      >
        {isHomePage ? (
          <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
        ) : (
          <Container
            maxWidth={isMobile ? "sm" : "lg"}
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Container>
        )}
      </Box>
      <Divider />
      <Footer />
    </Box>
  );
};

export default Layout;
