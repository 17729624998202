import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTheme } from "@mui/material/styles";

const logoStyle = {
  width: "140px",
  height: "auto",
};

const SocialIcon = ({ Icon, href, label }) => (
  <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
    <IconButton
      color="primary"
      aria-label={label}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        color: "secondary.main",
        "&:hover": {
          color: "primary.main",
        },
        transition: "color 0.3s ease",
      }}
    >
      <Icon />
    </IconButton>
  </motion.div>
);

const Footer = () => {
  const theme = useTheme();
  const logoSrc =
    theme.palette.mode === "light"
      ? "/logo-text-light-mode.svg"
      : "/logo-text-dark-mode.svg";

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "background.paper",
        py: { xs: 4, sm: 6 },
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={4}>
            <Box sx={{ mb: 3, textAlign: { xs: "center", sm: "left" } }}>
              <motion.img
                src={logoSrc}
                style={logoStyle}
                alt="Auburn Staffing logo"
                whileHover={{ scale: 1.05 }}
              />
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              paragraph
              sx={{
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Connecting talent with opportunity. Your partner in finding the
              right fit for your organization and career.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Company
              </Typography>
              <Link
                component={RouterLink}
                to="/about"
                color="secondary.500"
                display="block"
                sx={{
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                About Us
              </Link>
              <Link
                component={RouterLink}
                to="/services"
                color="secondary.500"
                display="block"
                sx={{
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                Services
              </Link>
              <Link
                component={RouterLink}
                to="/specialties"
                color="secondary.500"
                display="block"
                sx={{
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                Specialties
              </Link>
              <Link
                component={RouterLink}
                to="/contact"
                color="secondary.500"
                display="block"
                sx={{
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                Contact Us
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Connect
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  gap: 1.5,
                }}
              >
                <SocialIcon
                  Icon={TwitterIcon}
                  href="https://twitter.com/AuburnStaffing"
                  label="Twitter"
                />
                <SocialIcon
                  Icon={LinkedInIcon}
                  href="https://www.linkedin.com/company/auburn-staffing"
                  label="LinkedIn"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 5,
            pt: 3,
            borderTop: "1px solid",
            borderColor: "divider",
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {"Copyright © "}
            <Link component={RouterLink} to="/" color="inherit">
              Auburn Staffing
            </Link>{" "}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
