import React from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { motion } from "framer-motion";

const NavigationDrawer = ({
  navItems,
  location,
  handleNavigation,
  drawerOpen,
  toggleDrawer,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "85%", sm: "60%", md: "40%" },
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: theme.palette.mode === "light"
            ? theme.palette.background.paper
            : theme.palette.background.default,
          color: theme.palette.text.primary,
          borderTopLeftRadius: "12px",
          borderBottomLeftRadius: "12px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          boxShadow: `0px 4px 20px ${alpha(theme.palette.primary.dark, 0.2)}`,
          border: `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
        },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          paddingTop: 2,
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            mb: 2,
            color: theme.palette.primary.main,
          }}
        >
          Menu
        </Typography>
        <Divider sx={{ borderColor: alpha(theme.palette.primary.main, 0.3) }} />
        <List>
          {navItems.map((item) => (
            <ListItemButton
              key={item.name}
              selected={location.pathname === item.path}
              onClick={() => handleNavigation(item.path)}
              autoFocus={location.pathname === item.path}
              sx={{
                paddingY: 2,
                borderRadius: "8px",
                marginBottom: 1,
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.25),
                  transform: "scale(1.05)",
                },
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.35),
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.45),
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "40px",
                  color: theme.palette.text.primary,
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "rotate(20deg)",
                  },
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    fontWeight={location.pathname === item.path ? "bold" : "normal"}
                    sx={{ textShadow: `0px 1px 3px ${alpha(theme.palette.primary.dark, 0.3)}` }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
      <Divider sx={{ borderColor: alpha(theme.palette.primary.main, 0.3) }} />
      <Box sx={{ padding: 2, textAlign: "center", borderTop: `1px solid ${theme.palette.divider}` }}>
        <Link to="/" onClick={toggleDrawer(false)}>
          <motion.img
            src={
              theme.palette.mode === "light"
                ? "/logo-text-light-mode.svg"
                : "/logo-text-dark-mode.svg"
            }
            alt="Auburn Staffing logo"
            style={{ width: "80px", height: "auto", margin: "auto", padding: "20px 0", display: "block" }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          />
        </Link>
        <Button
          variant="contained"
          sx={{
            mt: 2,
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
            },
          }}
          onClick={() => {
            handleNavigation("/contact");
          }}
        >
          Contact Us
        </Button>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body2" color="textSecondary">
            © 2024 Auburn Staffing
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
