import React, { useContext, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useTheme,
  useMediaQuery,
  IconButton,
  Box,
  Container,
  Toolbar,
  AppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeContext } from "../ThemeContext";
import { alpha } from "@mui/material/styles";
import { motion } from "framer-motion";
import NavItem from "./NavItem";
import ThemeToggle from "./ThemeToggle";
import NavigationDrawer from "./NavigationDrawer";
import navItems from "./navItems";

const Navbar = () => {
  const { toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleNavigation = useCallback(
    (path) => {
      navigate(path);
      setDrawerOpen(false);
    },
    [navigate]
  );

  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }
      setDrawerOpen(open);
    },
    []
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        padding: 0,
      }}
    >
      <Container maxWidth="xxl" disableGutters>
        <Toolbar
          variant="regular"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            minWidth: "auto",
            mx: "auto",
            bgcolor: alpha(
              theme.palette.mode === "light"
                ? theme.palette.background.paper
                : theme.palette.background.default,
              0.8
            ),
            backdropFilter: "blur(4px)",
            maxHeight: 80,
            border: "1px solid",
            borderColor: "divider",
            boxShadow: `0 0 1px ${alpha(
              theme.palette.primary.main,
              theme.palette.mode === "light" ? 0.1 : 0.7
            )}, 1px 1.5px 2px -1px ${alpha(
              theme.palette.primary.main,
              theme.palette.mode === "light" ? 0.15 : 0.65
            )}, 4px 4px 12px -2.5px ${alpha(
              theme.palette.primary.main,
              theme.palette.mode === "light" ? 0.15 : 0.65
            )}`,
            px: { xs: 2, sm: 3, md: 4 },
          }}
          role="navigation"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 1,
              mb: 1,
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <motion.img
                src={
                  theme.palette.mode === "light"
                    ? "/logo-text-light-mode.svg"
                    : "/logo-text-dark-mode.svg"
                }
                alt="Auburn Staffing logo"
                aria-label="Auburn Staffing Home"
                style={{ width: "100px", height: "auto", cursor: "pointer", transition: "transform 0.3s" }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isMobile && (
              <Box sx={{ display: "flex", gap: 2 }}>
                {navItems.map((item) => (
                  <NavItem
                    key={item.name}
                    item={item}
                    selected={location.pathname === item.path}
                    onClick={() => handleNavigation(item.path)}
                  />
                ))}
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
            <ThemeToggle toggleTheme={toggleTheme} />
            {isMobile && (
              <>
                <IconButton
                  size="large"
                  aria-label="open navigation menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer(true)}
                  sx={{
                    color: theme.palette.mode === "light"
                      ? theme.palette.text.primary
                      : theme.palette.common.white,
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <NavigationDrawer
                  navItems={navItems}
                  location={location}
                  handleNavigation={handleNavigation}
                  drawerOpen={drawerOpen}
                  toggleDrawer={toggleDrawer}
                />
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default React.memo(Navbar);
