import React from "react";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Sun, Moon } from "phosphor-react";

const ThemeToggle = React.memo(({ toggleTheme }) => {
  const theme = useTheme();
  const prefersReducedMotion = useMediaQuery(
    "(prefers-reduced-motion: reduce)"
  );

  return (
    <IconButton
      onClick={toggleTheme}
      aria-label={`Switch to ${
        theme.palette.mode === "light" ? "dark" : "light"
      } mode`}
      sx={{
        ml: 1,
        transition: prefersReducedMotion ? "none" : "transform 0.2s",
        backgroundColor:
          theme.palette.mode === "light"
            ? alpha(theme.palette.primary.main, 0.1)
            : alpha(theme.palette.primary.main, 0.2),
        "&:hover": {
          transform: prefersReducedMotion ? "none" : "rotate(360deg)",
          backgroundColor:
            theme.palette.mode === "light"
              ? alpha(theme.palette.primary.main, 0.2)
              : alpha(theme.palette.primary.main, 0.3),
        },
      }}
    >
      {theme.palette.mode === "light" ? (
        <Moon size={24} weight="fill" color={theme.palette.text.primary} />
      ) : (
        <Sun size={24} weight="fill" color={theme.palette.text.primary} />
      )}
    </IconButton>
  );
});

export default ThemeToggle;
