import React from "react";
import { Button, Typography, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const NavItem = React.memo(({ item, selected, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      component="button"
      onClick={onClick}
      aria-label={item.ariaLabel}
      aria-current={selected ? "page" : undefined}
      sx={{
        py: "10px",
        px: "20px",
        transition: "all 0.3s ease",
        color: selected
          ? theme.palette.primary.main
          : theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "auto",
        textAlign: "center",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          color: theme.palette.primary.main,
          transform: "scale(1.05)",
        },
        ...(selected && {
          fontWeight: "bold",
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        }),
        "&:focus-visible": {
          outline: `3px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        },
      }}
    >
      {item.icon}
      <Typography variant="button" sx={{ ml: 1 }}>
        {item.name}
      </Typography>
    </Button>
  );
});

export default NavItem;
