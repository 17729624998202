import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { CircularProgress } from '@mui/material';
import Layout from "./components/Layout";
import { ThemeProvider } from "./ThemeContext";
import { GlobalStyles } from "./theme";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Specialties = lazy(() => import("./pages/Specialties"));
const Services = lazy(() => import("./pages/Services"));

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        style={{ position: "relative" }}  // Ensures smoother exit transitions
      >
        <Routes location={location}>
          <Route path="/" element={<><ScrollToTop /><Home /></>} />
          <Route path="/about" element={<><ScrollToTop /><About /></>} />
          <Route path="/specialties" element={<><ScrollToTop /><Specialties /></>} />
          <Route path="/services" element={<><ScrollToTop /><Services /></>} />
          <Route path="/contact" element={<><ScrollToTop /><Contact /></>} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

const LoadingSpinner = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "rgba(255, 255, 255, 0.8)",  // Add a semi-transparent background for better visibility
      position: "fixed",  // Ensure the spinner stays centered during transitions
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 9999,  // High z-index to overlay everything else
    }}
  >
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      <CircularProgress color="primary" size={60} thickness={5} />
    </motion.div>
  </Box>
);

const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <GlobalStyles />
      <Helmet>
        <title>Auburn Staffing</title>
        <meta
          name="description"
          content="Auburn Staffing provides exceptional staffing solutions tailored to your business needs."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Router>
        <Layout>
          <Suspense fallback={<LoadingSpinner />}>
            <AnimatedRoutes />
          </Suspense>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
